import React from 'react';

import { Box, Flex, Heading } from '@chakra-ui/react';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import ProfileMenuDesktop from 'ui/snippets/profileMenu/ProfileMenuDesktop';
import WalletMenuDesktop from 'ui/snippets/walletMenu/WalletMenuDesktop';
import config from 'configs/app';

const HomeSearchExplorerSection = () => {

    return (
        <Box
            w="100%"
            marginTop={{ base: '-57px', lg: '-80px' }}
            // backgroundImage={'url(/static/bannerpionescan.png)'}
            bgPosition={{ base: 'left', lg:'right' }}
            bgSize={'cover'}
            pt={{ base: '96px', lg: '196px' }}
            px={{ base: '24px', lg: '48px' }}
            pb={{ base: '25%', lg: '96px' }}
            minW={{ base: 'unset', lg: '900px' }}
            data-label="hero plate"
        >
            <Flex mb={{ base: 3, lg: 5 }} justifyContent="space-between" alignItems="center">
                <Heading
                    as="h1"
                    size={{ base: 'md', lg: "2xl" ,xl: '2xl' }}
                    lineHeight={{ base: '32px', lg: '50px' }}
                    pl={{base: 0, lg: '145px'}}
                    flexGrow={1}
                    fontWeight={{base: 600, lg: 700}}
                    // paddingLeft={'20px'}
                    // marginBottom={'-40px'}
                    textAlign={'center'}
                    color={config.UI.homepage.plate.textColor}
                    sx={{
                        '@media screen and (max-width: 380px)': {
                          fontSize: '21px',
                        },
                        '@media screen and (min-width: 600px) and (max-width: 999px)': {
                            fontSize: '30px',
                          },
                      }}
                      textColor={'#FF5B5D'}
                >
                    {config.chain.name}
                </Heading>
                <Box display={{ base: 'none', lg: 'flex' }}>
                    {config.features.account.isEnabled && <ProfileMenuDesktop isHomePage />}
                    {config.features.blockchainInteraction.isEnabled && <WalletMenuDesktop isHomePage />}
                </Box>
            </Flex>
            {/* <Flex mb={{ base: 3, lg: 5 }} justifyContent="space-between" alignItems="center">
                <Heading
                    as="h1"
                    size={{ base: 'sm', lg: "2xl" }}
                    lineHeight={{ base: '22px', lg: '50px' }}
                    flexGrow={1}
                    fontWeight={{ base: 600, lg: 700 }}
                    paddingLeft={{ base: '0px', lg: '20px' }}
                    marginBottom={{ base: '-20px', lg: '-40px' }}
                    // textAlign="justify"
                    color={config.UI.homepage.plate.textColor}
                    sx={{
                        '@media screen and (max-width: 350px)': {
                          fontSize: '18px',
                        }
                      }}
                >
                    {config.chain.name}
                </Heading>
                <Box display={{ base: 'none', md: 'flex', lg: 'flex' }}>
                    {config.features.account.isEnabled && <ProfileMenuDesktop isHomePage />}
                    {config.features.blockchainInteraction.isEnabled && <WalletMenuDesktop isHomePage />}
                </Box>
            </Flex> */}
            <SearchBar isHomepage />
        </Box>

    )
}

export default HomeSearchExplorerSection;
