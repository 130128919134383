import { Box, Image, chakra, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import IconSvg from "ui/shared/IconSvg";

interface Props {
  className?: string;
  TokenAddress?: string;
}
interface Coin {
  name: string;
  path: string;
  address: string;
}
const TokenLogoPlaceholder = ({ className, TokenAddress }: Props) => {
  const bgColor = useColorModeValue("gray.200", "gray.600");
  const color = useColorModeValue("gray.400", "gray.200");
  const [mainCoin, setMainCoin] = useState<Coin | null>(null) 

  const checkMainCoin = () => {
    const listCoin: Coin[] = [
      {
        name: "ETH",
        path: "/static/coin-logo/ETH-logo.png",
        address: "0xE8608fAfb809169e81290f470F75e5F63Cb703F2",
      },
      {
        name: "BNB",
        path: "/static/coin-logo/BNB-logo.png",
        address: "0xc435379ad7f2C76D806742a6b8b6122BEf4fEaa8",
      },
      {
        name: "USDT",
        path: "/static/coin-logo/USDT-logo.png",
        address: "0xC2C94bc58937CAbdc9440b2a953Ad16d3F7c6925",
      },
      {
        name: "BTC",
        path: "/static/coin-logo/BTC-logo.png",
        address: "0x783aA48020cdf84D299251a7d53adD7045a41265",
      },
    ];
    listCoin.forEach((token, index) => {
      if(TokenAddress === token.address) {
        setMainCoin(token)
      }
    });
  };

  useEffect(() => {
    checkMainCoin()
  }, [TokenAddress])

  if (!!mainCoin) {
    return (
      <Box h="100%" w="auto">
        <Image
          className={className}
          src={mainCoin.path}
          alt={mainCoin.name}
        />
      </Box>
    );
  }
  return (
    <IconSvg
      className={className}
      fontWeight={600}
      bgColor={bgColor}
      color={color}
      borderRadius="base"
      name="token-placeholder"
      transitionProperty="background-color,color"
      transitionDuration="normal"
      transitionTimingFunction="ease"
    />
  );
};

export default chakra(TokenLogoPlaceholder);
